<template>
  <v-app id="inspire">
    <ConnectHeader></ConnectHeader>
    <v-main style="background-color:#eef5f9">
      <v-container fluid class="page-wrapper">
        <router-view />
      </v-container>
    </v-main>
    <Footer></Footer>
  </v-app>
</template>

<script>
import ConnectHeader from "./header/ConnectHeader";
import Footer from "./footer/Footer";

export default {
  name: "ConnectLayout",

  components: {
    ConnectHeader,
    Footer
  },

  props: {
    source: String
  }
};
</script>

<style></style>
