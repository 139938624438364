<template>
  <v-app-bar app elevation="4" clipped-left clipped-right color="primary" dark>
    <v-toolbar-title class="pt-1 align-center d-flex">
      <span class="logo-text ml-2 mr-1">
        <span class="cursor-default">Firmar</span
        ><span class="cursor-default font-weight-bold">Online</span>
      </span>
    </v-toolbar-title>

    <v-spacer />

    <!---User -->
    <v-menu
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      active-class="highlighted"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="13"
          offset-y="13"
          class="ms-4"
          dot
        >
          <v-avatar size="40px" v-bind="attrs" v-on="on">
            <v-icon size="36px">mdi-account-circle</v-icon>
          </v-avatar>
        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pl-4 pt-2">
          <v-avatar size="40px">
            <v-icon size="46px">mdi-account-circle</v-icon>
          </v-avatar>
          <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align: middle"
          >
            <span
              class="ellipsis-dots user-name-app-bar text--primary font-weight-semibold mb-n1"
            >
              {{ username }}</span
            >
            <small class="text--disabled mt-1">{{
              credits + " créditos"
            }}</small>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Settings -->
        <v-list-item link class="pl-6" :to="route()">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCogOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Ajustes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-2"></v-divider>

        <!-- FAQ -->
        <v-list-item link class="pl-6" @click="onClickGoCentroAyuda">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiHelpCircleOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Soporte</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Contact -->
        <v-list-item link class="pl-6" @click="onClickGoContactaAsesor">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiPhoneInTalkOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Contáctanos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2"></v-divider>

        <!-- Logout -->
        <v-list-item link class="pl-6" @click.prevent="logout">
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cerrar Sesión</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <!---User -->
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations } from "vuex";
import { GoToUrl } from "../../../utils/utils";
import {
  mdiCogOutline,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
  mdiPhoneInTalkOutline
} from "@mdi/js";

export default {
  name: "ConnectHeader",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: false
    },
    hiddenOptions: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    userprofile: [
      { title: "Ajustes", to: "/printersettings", click: undefined },
      { title: "Cerrar Sesión", to: "/" }
    ],
    icons: {
      mdiCogOutline,
      mdiHelpCircleOutline,
      mdiLogoutVariant,
      mdiPhoneInTalkOutline
    }
  }),

  computed: {
    ...mapState(["Sidebar_drawer"]),
    logoSubtitle() {
      return this.$store.state.membershipPlanName;
    },
    credits() {
      return this.$store.state.user.limitCredits &&
        this.$store.state.user.limitCredits !== "-"
        ? this.$store.state.user.limitCredits -
            this.$store.state.user.consumedCredits
        : 0;
    },
    username() {
      return this.$store.state.user.userProfile != undefined
        ? this.$store.state.user.userProfile.fullName
        : "";
    }
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER"
    }),
    route() {
      return (
        "/connect/" +
        location.pathname.split("/")[location.pathname.split("/").length - 1] +
        "settings"
      );
    },
    logout() {
      this.$store.dispatch("logout");
      this.$auth.signOut("/");
    },
    onClickSubtitle() {
      this.onUpgradePlan();
    },
    onClickGoCentroAyuda() {
      GoToUrl("https://soporte.firmar.online/");
    },
    onClickGoContactaAsesor() {
      GoToUrl("https://firmar.online/contactanos/");
    }
  }
};
</script>

<style lang="scss">
.hidelogo {
  display: none;
}
.searchinput {
  position: absolute;
  width: 100%;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 0 15px;
}
.descpart {
  max-width: 220px;
}
</style>
